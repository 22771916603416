<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          <div class="row">
            <div class="col-md-6 d-flex justify-content-end">

            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
              <span class="font-lg alert-success mr-4">Общий долг: <b>{{ all_sum }}</b></span>
              <!--              <CButton color="danger" @click="deleteAll()">-->
              <!--                Удалить всех-->
              <!--              </CButton>-->
              <CButton color="success" @click="createNew()">
                Создать нового клиента
              </CButton>

            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >

            <td slot="action" slot-scope="{ item,index }" class="d-flex">
              <CButton color="danger" @click="deleteItem(item,index)" class="mr-2">
                Удалить
              </CButton>

              <CButton color="warning" @click="changeItem(item,index)" class="mr-2">
                Изменить
              </CButton>

              <CButton color="success" @click="viewItem(item,index)">
                Посмотреть
              </CButton>
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal title="Создать нового клиента" :show.sync="warningModal">
      <CCard>
        <CCardBody>
          <CForm v-on:submit="submitForm()">
            <CInput type="text" v-model="traffic.code_of_client" label="Код клиента" horizontal/>
            <CInput type="text" v-model="traffic.name_of_client" label="ФИО" horizontal/>
            <CInput type="text" v-model="traffic.address" label="Адрес" horizontal/>
            <CInput type="text" v-model="traffic.summa" label="Сумма" horizontal
            />
            <CButton color="success" type="submit">
              Сохранить
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CModal>

    <CModal title="Создать нового клиента" :show.sync="changeModal">
      <CCard>
        <CCardBody>
          <CForm v-on:submit="submitChangeForm()">
            <CInput type="text" disabled="" v-model="change_traffic.code_of_client" label="Код клиента" horizontal/>
            <CInput type="text" disabled="" v-model="change_traffic.name_of_client" label="ФИО" horizontal/>
            <CInput type="text" v-model="change_traffic.address" label="Адрес" horizontal/>
            <CInput type="text" disabled="" v-model="change_traffic.summa" label="Сумма" horizontal
            />
            <CButton color="success" type="submit">
              Сохранить
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CModal>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </CRow>
</template>

<script>

const qs = require('qs')
const axios = require('axios')
export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum: 0,
      warning: false,
      success: false,
      alert_message: '',
      changeModal: false,
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      traffic: {},
      change_traffic: {},
      file_path: '',
      fileData: '',
      tableItems: [],
      tableFields: [
        {key: "code_of_client", label: "Код"},
        {key: "name_of_client", label: "ФИО"},
        {key: "address", label: "Адрес"},
        {key: "summa", label: "Сумма долга"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    changeItem(item, index) {
      this.changeModal = !this.changeModal;
      this.change_traffic = item;
      console.log(this.change_traffic);
    },
    submitChangeForm() {
      event.preventDefault();
      let app = this;
      app.tableItems = [];
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/traffics/update-address/' + this.change_traffic.code_of_client,
            data: qs.stringify(this.change_traffic),
          })
          .then(({data}) => {
            app.changeModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
            app.getResults();
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)
      })
    },

    submitForm() {
      event.preventDefault();
      let app = this;
      app.tableItems = [];
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/traffics/create',
            data: qs.stringify(this.traffic),
          })
          .then(({data}) => {

            app.warningModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
            app.getResults();
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)

      })
    },
    createNew() {
      this.warningModal = !this.warningModal;
    },
    viewItem(item, index) {
      let app = this;
      this.$router.push('/clients/' + item.code_of_client);
    },
    deleteItem(item, index) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/traffics/delete/' + item.id).then(response => {
        app.tableItems.splice(index, 1);
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    getResults() {

      console.log('test');
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/traffics/get').then(response => {
        let traffics = response.data.traffics;
        for (const [key, value] of Object.entries(traffics)) {
          value.action = true;
          app.tableItems.push(value);
          app.all_sum += value.summa;
        }
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
